:root {
    --upload-container-border: 0.5rem;
    --glass_shader: rgba(139, 139, 139, 0.57);
    --cyan-alienware: #00f2f0;
    --purple-alienware: #b68bff;
    --delay-top: 20s;
    --delay-upmid: 30s;
    --delay-botmid: 40s;
    --delay-bottom: 50s;
}

.body-dark {
    background-color: #000;
}

body {
    color: #333;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*----- Loop hero logos and pause on hover/focus -----*/
.loop-container.top {
    -webkit-animation: logoloop var(--delay-top) linear infinite;
    -moz-animation: logoloop var(--delay-top) linear infinite;
    -o-animation: logoloop var(--delay-top) linear infinite;
    animation: logoloop var(--delay-top) linear infinite;
}
.loop-container.upmid {
    -webkit-animation: logoloop var(--delay-upmid) linear infinite;
    -moz-animation: logoloop var(--delay-upmid) linear infinite;
    -o-animation: logoloop var(--delay-upmid) linear infinite;
    animation: logoloop var(--delay-upmid) linear infinite;
}
.loop-container.botmid {
    -webkit-animation: logoloop var(--delay-botmid) linear infinite;
    -moz-animation: logoloop var(--delay-botmid) linear infinite;
    -o-animation: logoloop var(--delay-botmid) linear infinite;
    animation: logoloop var(--delay-botmid) linear infinite;
}
.loop-container.bottom {
    -webkit-animation: logoloop var(--delay-bottom) linear infinite;
    -moz-animation: logoloop var(--delay-bottom) linear infinite;
    -o-animation: logoloop var(--delay-bottom) linear infinite;
    animation: logoloop var(--delay-bottom) linear infinite;
}
@media (prefers-reduced-motion) {
    .loop-container {
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
    }
}

@keyframes rotate {
    from {
        transform: rotateX(0) rotateY(-180deg) rotateZ(0deg);
    }
    to {
        transform: rotateX(0) rotateY(-180deg) rotateZ(360deg);
    }
}

.rotating {
    animation: rotate 2s ease-in-out infinite;
}


@-webkit-keyframes logoloop {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}
@-moz-keyframes logoloop {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}
@-o-keyframes logoloop {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}
@keyframes logoloop {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes rotateAndScaleImageWrapperInner {
    0% {
        transform: scale(0) rotateZ(0deg);
    }
    100% {
        transform: scale(1.3) rotateZ(15deg);
    }
}
@keyframes fadeInAndMoveLogoLink {
    0% {
        opacity: 0;
        transform: translateY(0.5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fadeInAndMoveMainHeading {
    0% {
        opacity: 0;
        transform: translateY(0.5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fadeInAndMoveUploader {
    0% {
        opacity: 0;
        transform: translateY(0.5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fadeInAndMoveFooterContainer {
    0% {
        opacity: 0;
        transform: translateY(0.5em);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

a {
    color: rgba(255, 255, 255, 0.26);
}

.image-wrapper-inner {
    animation: rotateAndScaleImageWrapperInner 3s ease-in-out forwards;
}
.logo-link {
    animation: fadeInAndMoveLogoLink 1s ease-in-out 3s forwards;
}
.main-heading {
    animation: fadeInAndMoveMainHeading 1s ease-in-out 4s forwards;
}
.driploader {
    animation: fadeInAndMoveUploader 1s ease-in-out 4.5s forwards;
}
.footer-container {
    animation: fadeInAndMoveFooterContainer 1s ease-in-out 5.6s forwards;
}
.gradient-border-mask {
    display: flow-root;
    position: relative;
    padding: 1rem;
}
.gradient-border-mask::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20rem;
    border: 1rem solid transparent;
    background: linear-gradient(290deg, rgba(24, 172, 242, 1) 0%, rgba(103, 73, 212, 1) 33%, rgba(237, 71, 120, 1) 66%, rgba(252, 116, 66, 1) 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.border-anim-gradient {
    animation: spin 8s linear infinite;
    background: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 0) 70%, #4353ff 85%, rgba(255, 255, 255, 0) 85%);
    width: 100vw;
    height: 100vw;
    z-index: -1;
    width: 100%;
    filter: blur(30px);
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    position: absolute;
    transform: rotate(-55deg);
}

.border-anim-gradient.reverse {
    background: conic-gradient(from 270deg at 50% 50%, rgba(255, 255, 255, 0) 70%, #4353ff 85%, rgba(255, 255, 255, 0) 85%);
}

h1 {
    color: #fff;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 80px;
    font-weight: 700;
    line-height: 44px;
}

a {
    font-style: normal;
    text-decoration: none;
}

.dark {
    background-color: #000;
}
.page-wrapper-full-w-outer {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: block;
    position: fixed;
    overflow: hidden;
}

.parent-container {
    z-index: 100;
    width: 100%;
    height: 100vh;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column-reverse;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    display: block;
}

.child-container {
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.intro-section {
    flex-direction: column;
    align-items: center;
    display: flex;
}

.main-heading {
    opacity: 0;
    letter-spacing: -1.33px;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-family: Messinasans webfont, sans-serif;
    font-size: 4em;
    line-height: 1.2em;
}

.footer-container {
    width: 100%;
    opacity: 0;
    flex-wrap: nowrap;
    flex: 0 auto;
    justify-content: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    overflow: hidden;
}

.footer--text {
    color: rgba(255, 255, 255, 0.25);
    letter-spacing: -1.33px;
    flex: none;
    margin-bottom: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-family: Messinasans webfont, sans-serif;
    font-size: 1.1em;
}

.image-row-wrapper {
    flex: none;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
}

.image-wrapper-inner {
    z-index: -1;
    width: 100vw;
    height: 100vh;
    opacity: 0.15;
    flex-direction: column;
    flex: 0 auto;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    position: absolute;
    transform: rotate(15deg);
}

.single-bg-image {
    width: 12.5em;
    height: 12.5em;
    background-image: url("../images/5.webp");
    background-position: 50%;
    background-size: cover;
    border-radius: 1.2rem;
    margin-left: 1em;
    margin-right: 1em;
}

.single-bg-image._4 {
    background-image: url("../images/20.webp");
    background-position: 50% 0;
}

.single-bg-image._1 {
    background-image: url("../images/3.webp");
}

.single-bg-image._3 {
    background-image: url("../images/7.webp");
    background-position: 50% 0;
}

.single-bg-image._6 {
    background-image: url("../images/21.webp");
    background-position: 50% 0;
}

.single-bg-image._8 {
    background-image: url("../images/19.webp");
}

.single-bg-image._9 {
    background-image: url("../images/9.webp");
    background-position: 100% 0;
}

.single-bg-image._11 {
    background-image: url("../images/5.webp");
    background-position: 50%;
}

.single-bg-image._13 {
    background-image: url("../images/24.webp");
    background-position: 50%;
}

.single-bg-image._14 {
    background-image: url("../images/8.webp");
    background-position: 50% 0;
}

.single-bg-image._16 {
    background-image: url("../images/16.webp");
    background-position: 50% 0;
}

.single-bg-image._18 {
    background-image: url("../images/14.webp");
}

.single-bg-image._19 {
    background-image: url("../images/6.webp");
    background-position: 50%;
}

.single-bg-image-long {
    width: 22.5em;
    height: 12.5em;
    background-image: url("../images/6.webp");
    background-position: 50%;
    background-size: cover;
    border-radius: 1.2rem;
    margin-left: 1em;
    margin-right: 1em;
}

.single-bg-image-long._2 {
    width: 22.5em;
    background-image: url("../images/12.webp");
}

.single-bg-image-long._5 {
    background-image: url("../images/15.webp");
}

.single-bg-image-long._7 {
    background-image: url("../images/34.webp");
    background-position: 50% 70%;
}

.single-bg-image-long._10 {
    background-image: url("../images/30.webp");
    background-position: 50% 70%;
}

.single-bg-image-long._12 {
    background-image: url("../images/32.webp");
    background-position: 50% 60%;
}

.single-bg-image-long._15 {
    background-image: url("../images/29.webp");
    background-position: 50% 60%;
}

.single-bg-image-long._17 {
    background-image: url("../images/31.webp");
    background-position: 50% 75%;
}

.single-bg-image-long._20 {
    background-image: url("../images/18.webp");
    background-position: 50% 20%;
}

.loop-container {
    flex-wrap: nowrap;
    flex: 0 auto;
    justify-content: flex-start;
    display: flex;
}

.logo {
    width: 6em;
}

.logo-link {
    opacity: 0;
    margin-top: 3em;
}

.styled-inline_text {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(146deg, #534fff, #9833ff 70%);
    -webkit-background-clip: text;
    background-clip: text;
}

.uploader {
    width: 35vw;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    overflow: hidden;
}

.form {
    height: auto;
    max-width: 380px;
    background-color: #fff;
    border-radius: 16px;
    align-items: center;
    padding: 4px 4px 4px 8px;
    display: flex;
}

.form.center {
    height: auto;
    max-width: 100%;
    background-color: red;
    border-radius: 8rem;
    align-items: center;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    line-height: 1;
    position: relative;
}

.form.center.glass {
    -webkit-backdrop-filter: blur(1.8em);
    backdrop-filter: blur(1.8em);
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(172deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.1) 59%);
    justify-content: flex-end;
}

.mobile-break {
    display: none;
}

.text-field {
    height: 4rem;
    color: #131313;
    border: 2px solid #fff;
    border-radius: 8rem;
    margin-bottom: 0;
    padding: 0.5rem 4.8rem 0.5rem 1.5rem;
    font-family: Messinasans webfont, sans-serif;
    font-size: 165%;
    font-weight: 400;
}

.text-field:focus {
    border-color: #5221e8;
}

.text-field::-ms-input-placeholder {
    color: #a3aeb8;
    font-weight: 400;
}

.text-field::placeholder {
    color: #a3aeb8;
    font-weight: 400;
}

.text-field.success_load {
    -webkit-backdrop-filter: blur(1.8rem);
    backdrop-filter: blur(1.8rem);
    color: #e8d8d8;
    border-color: #236251;
    justify-content: center;
    align-items: center;
    padding-right: 1.5rem;
    display: flex;
}

.text-field.success_load.error {
    border-color: #762728;
    box-shadow: 0 0 9px 2px rgba(255, 0, 0, 0.22);
}

.text-field.label {
    width: 100%;
    color: #a5aeb7;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    align-self: center;
    align-items: center;
    padding-right: 1.5rem;
    font-size: 1.8vw;
    display: flex;
}

.text-field.label:hover {
    border-width: 3px;
    border-color: #614bf6;
}

.button {
    background-color: #5221e8;
    border-radius: 12px;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 500;
}

.button.form-btn {
    z-index: 1;
    width: 4rem;
    height: 4rem;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../images/share.svg"), linear-gradient(290deg, #534fff, #9833ff 69%);
    background-position: 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 55%, auto;
    border-radius: 8rem;
    align-self: center;
    padding: 0.5rem;
    font-size: 1.8vw;
    line-height: 1;
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
}

.button.form-btn.hourglassoverlay {
    background-image: none;
    align-items: center;
    padding: 0;
    display: flex;
}

.success-message {
    color: #131313;
    background-color: rgba(0, 0, 0, 0);
}

.error-message {
    background-color: rgba(0, 0, 0, 0);
}

.link {
    color: rgba(255, 255, 255, 0.27);
}

.link-2 {
    color: rgba(255, 255, 255, 0.25);
}

@keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(0.5em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

.waiting_messages {
    color: #b7b7b7;
    text-align: left;
    font-family: Messinasans webfont, sans-serif;
    font-size: 1.45em;
    line-height: 1.3em;
    display: block;
}

  
.generated_message {
    width: 50vw;
    max-height: 50%;
    opacity: 1;
    padding-top: 20px;
    display: none;
    position: relative;
    animation: fadeUp 1s ease-in-out;
}


.message-container {
    -webkit-backdrop-filter: blur(1.8em);
    backdrop-filter: blur(1.8em);
    background-image: linear-gradient(rgba(212, 212, 212, 0.05), rgba(212, 212, 212, 0.05));
    border-radius: 2.5rem;
    margin: 0px 0px 1rem 0px;
    justify-content: flex-start;
    margin-bottom: 1rem;
    padding: 2.3rem;
    display: flex;
}

.lottie-animation-2 {
    z-index: 1000;
    width: 4rem;
    justify-content: center;
    align-items: center;
    display: flex;
    position: static;
    top: 2.2rem;
    left: 47.5%;
}

.generated_pargraph {
    color: #b7b7b7;
    text-align: left;
    font-family: Messinasans webfont, sans-serif;
    font-size: 1.45em;
    line-height: 1.3em;
    display: block;
}

.generated_pargraph.rating {
    color: #000;
    margin-bottom: 0;
}

.lottie_container {
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: -2rem;
    display: flex;
}

.progress_bar {
    width: 100%;
    height: 1rem;
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
    background-color: #474040;
    border-radius: 1em;
}

.progress_bar.inner_level_indicator {
    width: 10%;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(96deg, rgba(82, 69, 224, 0.85), rgba(140, 58, 245, 0.7));
}

.arrow_pointer {
    height: 1.5em;
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    padding-left: 5%;
    display: flex;
}

.image-2 {
    height: 1.5em;
    opacity: 0.53;
    filter: invert();
    display: block;
}

.rate_text {
    height: auto;
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
    background-color: rgba(209, 209, 209, 0.63);
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    display: flex;
}

.input-box {
    width: 100%;
    height: 4em;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.driploader {
    opacity: 0;
}

.button {
    width: 50svw;
    border: 0.4rem solid var(--cyan-alienware);
    box-shadow: 0 0 25px 0 var(--cyan-alienware), 0 0 10px 0 var(--cyan-alienware);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 8rem;
    padding: 1rem;
    font-family: Messinasans webfont, sans-serif;
    font-size: 1.8em;
}

.button.arrow-button {
    background-color: rgba(19, 19, 19, 0.15);
}

.arrow-button {
    background-color: #0a081c;
    border: 1px solid #6911f5;
    border-radius: 999px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    box-shadow: 1px 1px 3px #89ffe1;
    min-width: 30svw;
}

.arrow-button:hover {
    box-shadow: -1px -1px 3px #89ffe1;
}

.arrow-button._2 {
    width: auto;
    background-image: none;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
}

.upload-wrapper {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    border-radius: 20rem;
    justify-content: space-between;
    align-items: center;
    display: block;
}

.upload-wrapper.gradient-border-mask {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    padding: 1rem;
}

.upload-wrapper.gradient-border-mask.button-text {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    display: flex;
    overflow: hidden;
}

.button-text {
    color: #fff;
    text-align: left;
    white-space: nowrap;
    font-family: Messinasans webfont, sans-serif;
    font-size: 2em;
}

.icon-embed-xsmall {
    z-index: 100;
    width: 1.5rem;
    height: 1.5rem;
    color: #fff;
    position: relative;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 10%;
}

.upload-container {
    z-index: 5;
    width: 45svw;
    max-width: 45svw;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    border-style: solid;
    border-width: var(--upload-container-border);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(33, 33, 33, 0.75);
    border-color: #8b35e3;
    border-radius: 8rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem 1rem 1.5rem;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    display: block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 40px #9833ff, 1px 1px 6px #9833ff, 0 0 10px #9833ff;
}

.file-upload-box {
    color: #fff;
    flex-direction: row;
    align-items: center;
    display: flex;
    position: relative;
}

.file-upload-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.upload-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%; /* Adjust this value based on your design needs */
    line-height: 1.5em;
}

.upload-text.uploading {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 200%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.buttons-row {
    justify-content: flex-end;
    align-items: center;
    margin: 1rem;
    display: flex;
}

.send_wrapper {
    z-index: 100;
    margin-right: var(--upload-container-border);
    position: absolute;
}

.send_button {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 8rem;
    justify-content: center;
    align-items: center;
    padding: 0 1.2rem;
    display: flex;
}

.s {
    width: 800rem;
}

.upload-icon {
    width: 4rem;
    height: 4rem;
    color: #b68bff;
    -webkit-text-fill-color: inherit;
    transform-style: preserve-3d;
    background-clip: border-box;
    transform: rotateX(0) rotateY(-180deg) rotateZ(15deg);
    margin-right: 5px;
}

.generator_container {
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    padding-bottom: 10px;
    /* padding-right: 2rem; */
    display: flex;
  }
  
  .polaroid-wrapper {
    width: 30%;
    height: 25rem;
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
    object-fit: cover;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 1rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    display: flex;
    position: relative;
  }
  
  .polaroid-image-layers {
    width: 100%;
    height: 90%;
    border-radius: 1rem;
    padding: 0;
    position: relative;
  }
  
  .polaroid-text-layer {
    width: 100%;
    height: 8%;
    justify-content: space-around;
    align-items: center;
    display: flex;
  }
  
  .image-container {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    overflow: hidden;
  }
  
  .image-container.layer-top-base {
    z-index: 2;
    opacity: .3;
    mix-blend-mode: multiply;
  }
  
  .image-container.paper {
    z-index: 3;
    mix-blend-mode: multiply;
  }
  
  .image-container.lens-dirt {
    z-index: 4;
    opacity: .25;
    mix-blend-mode: color-dodge;
  }
  
  .image-container.grain {
    z-index: 5;
  }
  
  .absolute_text {
    color: rgba(255, 255, 255, .23);
    font-size: 10px;
    line-height: 15px;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .dripcheck_color_logo {
    z-index: 100;
    height: 10%;
    opacity: .6;
    mix-blend-mode: overlay;
    padding-bottom: .25rem;
    padding-right: .25rem;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
  }
  
  .generated_pargraph.rating {
    text-align: center;
}
  .polaroid-rating {
    color: #b7b7b7;
    text-align: left;
    font-family: Speedy Marker, sans-serif;
    font-size: 1.4em;
    line-height: 1.3em;
    display: block;
  }
  
  .polaroid-rating.rating {
    color: #000;
    margin-bottom: 0;
  }
  
  .image-fill {
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    object-fit: cover;
  }

@media screen and (min-width: 1440px) {
    a {
        color: rgba(255, 255, 255, 0.26);
    }

    .text-field.label {
        font-size: 1.8vw;
    }

    .button.form-btn {
        text-align: center;
        font-size: 1.8vw;
    }
    .footer-container {
        padding-bottom: 3.5em;
    }
}

@media screen and (min-width: 1920px) {
    .uploader {
        max-width: 40rem;
    }

    .form.center {
        background-color: #fb0606;
        border: 0 solid #000;
        padding: 2rem;
        display: flex;
    }

    .text-field {
        height: 4rem;
        padding-right: 5rem;
        font-size: 1.5rem;
    }

    .polaroid-wrapper {
        height: 30rem;
    }

    .text-field.success_load {
        color: #e5e5e5;
        font-family: Messinasans webfont, sans-serif;
        font-weight: 700;
    }

    .text-field.label {
        height: 4.5rem;
        font-size: 1.4vw;
    }

    .button.form-btn {
        width: 4.5rem;
        height: 4.5rem;
        background-image: linear-gradient(290deg, #534fff, #9833ff 69%);
        font-size: 1.4vw;
    }

    .success-message,
    .error-message {
        background-color: rgba(0, 0, 0, 0);
    }

    .upload-container {
        padding: 1.5rem 2rem 1.5rem 2rem;
    }
    .footer-container {
        padding-bottom: 3.5em;
    }
}

@media screen and (max-width: 991px) {
    .message-container {
        flex-direction: row;
      }

    .uploader {
        width: 80vw;
    }
    .button.arrow- {
        width: 80svw;
    }

    .generated_message {
        width: 90vw;
    }

    .arrow-button {
        min-width: 70svw;
    }

    .arrow-button:hover {
        background-position: 70%;
    }
    .text-field {
        font-size: 200%;
    }

    .text-field.label {
        font-size: 3.5vw;
    }

    .button {
        height: 56px;
        border-radius: 12rem;
    }

    .button.form-btn {
        font-size: 3.5vw;
    }

    .upload-container {
        width: 80svw;
        max-width: 80svw;
    }
    .generator_container {
        padding-right: 1rem;
      }
    
      .polaroid-wrapper {
        width: 45%;
        height: 20rem;
      }
    
      .polaroid-rating {
        font-size: 1.6em;
      }
    
      .image-fill {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
}

@media screen and (max-width: 767px) {
    .upload-icon {
        margin-right: 0px;
    }
    .message-container {
        flex-direction: column;
        align-items: center;
      }
    
      .generator_container {
        padding-bottom: 1.5rem;
        padding-right: 0;
      }
    
      .polaroid-wrapper {
        width: 50%;
      }

    .uploader {
        width: 80vw;
    }

    .text-field {
        font-size: 180%;
    }

    .text-field.label,
    .button.form-btn {
        font-size: 4vw;
    }
}

@media screen and (max-width: 479px) {

    .send_button {
        padding: 0 0.8rem;
    }

    .main-heading {
        font-size: 3em;
    }

    .footer-container {
        align-items: center;
        padding-top: 4em;
        display: flex;
      }
    
      .footer--text {
        letter-spacing: -1.1px;
        line-height: 14px;
      }


    .mobile-break {
        display: block;
    }
    .button {
        width: 90svw;
        padding: 1rem;
        font-size: 1.6em;
    }
    .uploader {
        width: 100vw;
        padding-left: 10px;
        padding-right: 10px;
    }

    .form.center {
        background-color: rgba(0, 0, 0, 0);
        padding: 0;
    }

    .form.center.glass {
        padding: 1rem;
    }

    .text-field {
        height: 64px;
        text-align: left;
        border-color: #f7f7f7;
        padding-left: 1rem;
        padding-right: 4.3rem;
        font-size: 1.8em;
    }

    .text-field:focus {
        border-style: solid;
    }

    .text-field.success_load {
        padding-right: 1rem;
    }

    .text-field.label {
        justify-content: flex-start;
        padding-right: 1rem;
        font-size: 6vw;
    }

    .button.form-btn {
        width: 64px;
        height: 64px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 6vw;
        line-height: 5px;
        position: absolute;
    }

    .generated_message {
        width: 100vw;
        padding-left: 0;
        padding-right: 0;
    }

    .message-container {
        -webkit-backdrop-filter: blur(60px);
        backdrop-filter: blur(60px);
        border-radius: 1rem;
        margin: 5px 5px 1rem 5px;
        padding: 1rem 0.8rem 0.8rem;
    }

    .lottie-animation-2 {
        width: 3.3rem;
        top: 1.8rem;
        left: 39.5%;
    }

    .generated_pargraph {
        text-align: justify;
        font-size: 1.3em;
    }

    .generated_pargraph.rating {
        margin-bottom: 0;
        text-align: center;
    }

    .lottie_container {
        margin-bottom: -1.2rem;
    }

    .progress_bar {
        display: block;
    }

    .arrow_pointer {
        padding-left: 5%;
    }

    .image-2 {
        filter: invert();
    }

    .rate_text {
        height: auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1em;
        display: flex;
    }

    .input-box {
        height: 64px;
    }

    .upload-wrapper.gradient-border-mask.button-text {
        padding: 0.5rem;
    }

    .upload-icon {
        width: 3rem;
        height: 3rem;
    }

    .upload-container {
        width: 90svw;
        max-width: 90svw;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-width: 0.3rem;
        box-shadow: 0 0 40px #9833ff, 1px 1px 6px #9833ff, 0 0 5px #9833ff;
    }

    .buttons-row {
        margin: 0.5rem;
    }
    .polaroid-wrapper {
        width: 100%;
        height: 30rem;
      }
    
      .polaroid-rating {
        text-align: justify;
        font-size: 2em;
      }
    
      .polaroid-rating.rating {
        margin-bottom: 0;
      }

    .gradient-border-mask::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 20rem;
        border: 0.5rem solid transparent;
        background: linear-gradient(290deg, rgba(24, 172, 242, 1) 0%, rgba(103, 73, 212, 1) 33%, rgba(237, 71, 120, 1) 66%, rgba(252, 116, 66, 1) 100%) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }
}

@font-face {
    font-family: "Messinasans webfont";
    src: url("../fonts/messinasans-regular-webfont.woff2") format("woff2"), url("../fonts/messinasans-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Speedy Marker';
    src: url("../fonts/Speedy-Marker.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }